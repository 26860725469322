<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>企业管理</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-button @click='showEditModal()' type="primary" icon="plus" >新增</a-button>
                    </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                            <a-input v-model.trim="searchParams.search.app_name"  allowClear placeholder="请输入机构简称"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model.trim="searchParams.search.contact_name" allowClear placeholder="请输入联系人姓名"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model.trim="searchParams.search.cellphone" allowClear placeholder="请输入联系电话"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-radio-group :value="searchParams.search.app_status ">
                                <a-radio-button @click="handleTypeButChange('1')" value="1">
                                    使用中
                                </a-radio-button>    
                                <a-radio-button @click="handleTypeButChange('0')" value="0">
                                    已停用
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" html-type="submit" type="primary" >搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="wid"
                    :columns="columns" :dataSource="list">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="image" slot-scope="text, record">
                        <span @click="toModule(record)">
                            <LImg :src="text" style="width: 50px; height: 50px;cursor:pointer"/>
                        </span>
                        
                    </template>
                    <template slot="status" slot-scope="text,record">
                        <a-switch checkedChildren="开启" unCheckedChildren="关闭" :checked="text === 1" @change="changeStatus(record)"/>
                    </template>
                    <template slot="action" slot-scope="text,record">

                        <a href="javascript:;" @click="toclean(record)">清理</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" @click="showEditModal(record)">编辑</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" v-clipboard:copy="record.access_url" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</a>

                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <EditModal :isEdit='isEdit' :item='modalData' v-if="isEditModal"/>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'task_id',fixed: 'left'},
  {title: '机构图片', dataIndex: 'app_avatar', key: 'app_avatar' , scopedSlots: { customRender: 'image' }},
  {title: '机构简称', dataIndex: 'app_name', key: 'app_name'},
  {title: '联系人姓名', dataIndex: 'contact_name', key: 'contact_name'},
  {title: '联系电话', dataIndex: 'cellphone', key: 'cellphone' },
  {title: 'appid', dataIndex: 'appid',  key: 'appid' },
  {title: '应用状态', dataIndex: 'app_status', key: 'app_status', scopedSlots: { customRender: 'status' }}, 
  {title: '添加时间', dataIndex: 'created_at', key: 'created_at' },
  { title: '操作', key: 'operation', fixed: 'right', width:150, scopedSlots: { customRender: 'action' }}
]
import EditModal from './editModal'
import tableMixins from '@/common/mixins/table'
    export default {
        name:'enterpriseSon',
        provide() {
            return {
                parent: this
            }
        },
        mixins: [ tableMixins ],
        components:{EditModal},
        data() {
            return {
                columns,
                loading:false,
                isEditModal:false,
                isEdit:0,
            }
        },
        methods: {
            onCopy(){
                this.$message.success('已复制到剪切板!')
            },
            onError(){
                this.$message.error('操作失败!')
            },
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('enterpriseCompanyAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            recoveryItem(item) {
                let that = this
                this.$confirm({
                    title: `您确定要开启该APP吗 ?？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('enterpriseCompanyStatusAction', { data: { wid: item.wid } })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功!')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            deleteItem(item) {
                let that = this
                this.$confirm({
                    title: `您确定要关闭该APP ?? `,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('enterpriseCompanyStatusAction', { data: { wid: item.wid } })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功!')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            handleTypeButChange(e){
              if(this.searchParams.search.app_status == e){
                this.searchParams.search.app_status = undefined
              }else{
                this.searchParams.search.app_status = e
              }
              this.getList()
            },
            changeStatus(record){
                if (record.app_status === 1) {
                    this.deleteItem(record)
                } else {
                    this.recoveryItem(record)
                }
            },
            showEditModal(item){
                console.log(item)
                if(item){
                    this.isEdit = 1
                }else{
                    this.isEdit = 0
                }
                this.isEditModal = true
                this.modalData = item
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            toModule(val){
                this.$router.push(`/enterprise/appModule/${val.wid}`)
            },
            toclean(item){
                let that = this
                this.$confirm({
                    title: `您确定要清除该APP ?? `,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('enterpriseCompanyCleanAction', { data: { wid: item.wid } })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功!')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>