<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="50%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <LImg v-if="imageUrl" :src="imageUrl" style="width: 100px; height: 100px;"/>
          <div v-if="imageUrl" class="close" @click="closeImg($event)">&times;</div>
          <a-button v-if="!imageUrl" type="dashed" icon="file-image">选择图片</a-button>
        </div>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'app_avatar',
    label: '机构图片',
    type: 'upload',
    rules: [{ required: true, message: '请选择图片!' }]
  },
  {
    name: 'app_name',
    label: '机构简称',
    type: 'input',
    rules: [{ required: true, message: '请输入机构简称!' }],
  },
  {
    name: 'org_name',
    label: '机构全称',
    type: 'input',
    rules: [{ required: true, message: '请输入机构全称!' }],
  },
  {
    name: 'contact_name',
    label: '联系人姓名',
    type: 'input',
    rules: [{ required: true, message: '请输入联系人姓名!' }],
  },
  {
    name: 'cellphone',
    label: '联系电话',
    type: 'input',
    rules: [{ required: true, message: '请输入联系电话!' }],
  },
]
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  provide() {
    return {
      parent: this
    }
  },
  inject: ['parent'],
  props: {
    item: Object,
    isEdit: Number
  },
  components:{ImageModal},
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增应用',
      visible: false,
      confirmLoading: false,
      formData,
      imageUrl: '',
      imagesVisible: false,
      form: this.$form.createForm(this),
    }
  },

  async created() {
    this.visible = true
  },
  async mounted(){
    if(this.item){
      this.imageUrl = this.item.app_avatar
      await this.$nextTick()
      this.form.setFieldsValue({
          app_name:this.item.app_name,
          org_name:this.item.org_name,
          contact_name:this.item.contact_name,
          cellphone:this.item.cellphone,
      })
    }
  },
  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        console.log(this.isEdit)
        if (params) {
            console.log(123)
          params.app_avatar = this.imageUrl
          if(this.isEdit == 0){
              console.log(1234)
            await this.$store.dispatch('enterpriseCompanyAddAction', { data: params })
            .then(res=>{
                form.resetFields()
                this.$message.success('操作成功')
                this.parent.hideEditModal(1)
            })
            .catch(err=>{
                this.confirmLoading=false;
            })
          }else{
            params.wid = this.item.wid
            let res = await this.$store.dispatch('enterpriseCompanyUPdateAction', { data: params })
            .then(res=>{
                form.resetFields()
                this.$message.success('操作成功')
                this.parent.hideEditModal(1)
            })
            .catch(err=>{
                this.confirmLoading=false;
            })
          }
          
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        console.log(image)
        this.imageUrl = image.path
      }
      this.imagesVisible = false
    },
  }
}
</script>
